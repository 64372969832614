<template>
  <div class="cityInvite-container">
      <div class="cityInvite-box">
          <div class="cityInvite-box-middle">
              <div class="cityInvite-title">邀请加入</div>
              <div class="cityInvite-link">
                  <span>邀请链接</span>
                  <span>{{this.GLOBAL.share_url+'?p='+this.phone}}</span>
                  <span class="tag-read" :data-clipboard-text="this.GLOBAL.share_url+'?p='+this.phone" @click="copy">复制</span>
              </div>
              <div class="cityInvite-code">
                  <span>邀请二维码</span>
                  <div class="cityInvite-qrcode" ref="qrCodeUrl"></div>
              </div>
              
              <div class="cityInvite-selectNum">
                  <div class="">城市代理人手机号码</div>
                  <el-select v-model="number" placeholder="请选择手机号" class="select" @change="get">
                    <el-option
                    v-for="item in options"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
              </div>
              <div class="cityInvite-infor">邀请信息</div>
              <div class="cityInvite-detail">
                  <div class="cityInvite-detail-title">
                      <div>邀请人</div>
                      <div>手机号</div>
                      <div>加入时间</div>
                  </div>
                  <div>
                      <div v-for="(v,k) in list" :key="k" class="cityInvite-list">
                          <div>{{v.previousMobile}}</div>
                          <div>{{v.userMobile}}</div>
                         <div>{{v.createTime}}</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      

    </div>
</template>

<script>
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2';
export default {
  name: 'cityInvite',
  data(){
    return{
      number:'',
      options:[],
      list:[],
    //   code:'',
        phone:this.$cookie.getCookie('username')
    }
  },
  watch:{
       
  },
  components: {

  },
  
  mounted(){
      this.getData();
    //   this.getcode()
    this.number = this.$cookie.getCookie('username');
    this.get();
    this.creatQrCode()
  },
  methods:{
      tip(type,msg){
              return this.$message({
                  type:type,
                  message:msg,
                  showClose:true,
                  duration:1500
              })
          },
      copy(){
          var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.tip('success','复制成功')
      // 释放内存
      clipboard.destroy()
      })
      clipboard.on('error', e => {
      // 不支持复制
      console.log('该浏览器不支持自动复制')
      // 释放内存
      clipboard.destroy()
      })
      },
      creatQrCode() {
          var url = this.GLOBAL.share_url+'?p='+this.phone
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url, // 需要转换为二维码的内容
            width: 100,
            height: 100,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
      getData(){
          this.$http.post(this.GLOBAL.new_url+'/user/service/queryCityProxySubordinate',{
              userCode:this.$cookie.getCookie('u_id')
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                //   console.log(res)
              if(res.data.code == "200"){
                  this.options = res.data.data
              }
            })
      },
      get(){
          this.$http.post(this.GLOBAL.new_url+'/user/service/queryCityProxy',{
              userCode:this.$cookie.getCookie('u_id'),
              userMobile:this.number
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                //   console.log(res)
              if(res.data.code == "200"){
                  this.list = res.data.data;
                  this.list.map(x=>{
                      x.previousMobile = x.previousMobile.substr(0, 3) + '****' + x.previousMobile.substr(7)
                      x.userMobile = x.userMobile.substr(0, 3) + '****' + x.userMobile.substr(7)
                  })
              }
            })
      },
    //   getcode(){
    //       this.$http.post(this.GLOBAL.new_url+'/user/service/getPersonInfo',{
    //         userCode:this.$cookie.getCookie('u_id')
    //   },{
    //                 headers:{
    //                     AuthToken:this.$cookie.getCookie('token'),
    //                     'X-Request-Origin':'xcx'
    //                 }
    //                             }).then(res=>{
    //   // console.log(res)
    //   if(res.data.code== "200"){
    //     this.code = res.data.data.userInviteCode;
       
    //   }
    //   })
    //   }
  }
}

</script>

<style scoped>
    .cityInvite-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        background-color: #fff;
        padding-top: 12px;
        min-height: 950px;
        box-sizing: border-box;
    }
    .cityInvite-box-middle{
        width: 885px;
        margin: 0 auto;
        padding-bottom: 60px;
    }
    .cityInvite-box-middle>div{
        padding-left: 4px;
    }
    .cityInvite-title{
        height: 32px;
        border-bottom: 1px solid #d8d8d8;
        font-size: 16px;
        margin-bottom: 25px;
    }
    .cityInvite-link{
        height: 35px;
        font-size: 14px;
        margin-bottom: 18px;
        
    }
    .cityInvite-link>span{
        display: inline-block;
        vertical-align: middle;
        margin-right: 21px;
    }
    .cityInvite-link>span:nth-of-type(2){
        line-height: 36px;
        height: 36px;
        padding: 0 15px;
        border-radius: 3px;
        border: 1px solid #D8D8D8;
    }
    .cityInvite-link>span:nth-of-type(3){
        width: 80px;
        height: 36px;
        background: #EB5E00;
        border-radius: 3px;
        cursor: pointer;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }
    .cityInvite-code{
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 24px;
        height: 125px;
    }
    .cityInvite-code>span{
        font-size: 14px;
        float: left;
    }
    .cityInvite-qrcode{
        float: left;
        margin-left: 20px;
    }
    .cityInvite-selectNum{
        height: 94px;
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 24px;
        font-size: 16px;
    }
    .select{
        margin-top: 12px;
        font-size: 14px;
        width: 230px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 3px;
    }
    .cityInvite-infor{
        font-size: 16px;
        margin-bottom: 22px;
    }
    .cityInvite-detail{
        width: 870px;
        border:1px solid #EEECEC;
        border-bottom: none;
        padding-left: 0!important;
    }
    .cityInvite-detail-title{
        height: 53px;
        background: #F8F8F8;
    }
    .cityInvite-detail-title>div{
        float: left;
        width: 290px;
        height: 53px;
        text-align: center;
        line-height: 53px;
    }
    .cityInvite-list{
        height: 59px;
        border-bottom:1px solid #EEECEC;
    }
    .cityInvite-list>div{
        float: left;
        width: 290px;
        font-size: 14px;
        height: 59px;
        text-align: center;
        line-height: 59px;
    }
</style>
<style>
    .el-select-dropdown__item.selected {
    color: #606266;
    font-weight: 400;
}
.el-input__icon{
    height: 40px;
}
.el-select .el-input.is-focus .el-input__inner{
    border-color: #DCDFE6;
}
.el-select .el-input__inner:focus{
    border-color: #DCDFE6;
}
</style>
